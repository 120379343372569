<template>
  <div>logout</div>
</template>
<script>
import { http } from '@/utils/axios'

export default {
  name: 'Logout',
  async mounted () {
    http.post('/auth/logout').then(async res => {
      this.$buefy.toast.open({ message: res.data.message, position: 'is-top-right', type: 'is-success' })
      delete http.defaults.headers['Authorization']
      await this.$store.dispatch('setLogout', {})
      this.$router.push({ name: 'login' })
    })
        .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
  }
}
</script>
